import React from 'react'
import ReactDOM from 'react-dom'

// import '@westernunion/icons/wuds.icons.min.css'

import getPageData from './services/get-page-data.service'
import loadNativeAssets from './loadNativeAssets'

import App from '../src/App'

import reportWebVitals from './reportWebVitals'

(async () => {
  window.pageData = await getPageData()

  ReactDOM.hydrate(
    <React.StrictMode>
      <App page-data={ window.pageData } />
    </React.StrictMode>,
    document.getElementById('__wu')
  )

  loadNativeAssets()
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
