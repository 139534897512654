/* eslint-disable no-plusplus */
import axios from 'axios'

/**
 * Setting Switches in the session storage
 */
const setSwitches = (switches) => {
  if (
    isBrowser() &&
    sessionStorage.setItem('switches', switches)
  ) {
    sessionStorage.setItem('switches', switches)
  }
}

/**
 * Get Switches from the session storage
 */
const getSwitches = () => {
  try {
    const switchesStr = sessionStorage.getItem("switches")
    const validSwitchesStr = switchesStr.replace(/'/g, '"')
    return JSON.parse(validSwitchesStr)
  } catch (e) {
    return null
  }
}

/**
 * Get getPageUrl return the page url on the basie of page params
 */
const getPageUrl = page => {
  let pagePaths = { "login": "/web/user/login", "register": "/web/user/register" }
  if (
    isBrowser()
  ) {
    let splitPath = window.location.href
    splitPath = splitPath.split("/").slice(0, 5).join("/")
    return splitPath + pagePaths[page]
  }
}
const getUrl = () => {
  return `${window.location.protocol}//${window.location.host}`
}
/**
 * windowClick function add event listener on window click
 */
const windowClick = (ref, closeFn) => {
  window.addEventListener("click", event => {
    const popupList = ["signup-overlay", "verify-email-overlay"]
    const popUpName = sessionStorage.getItem("popUpName")

    if (
      popupList.indexOf(popUpName) !== -1 &&
      !(ref?.current?.contains(event.target))
    ) {
      sessionStorage.setItem("closeOverLay", "true")
      sessionStorage.removeItem("popUpName")
      closeFn()
    }
  })
}

let getOtScriptDomains = () => {
  const otScriptDomainStr = sessionStorage.getItem("otscriptpath")
  var validOtScriptDomainStr = otScriptDomainStr.replace(/'/g, '"')
  try {
    return JSON.parse(validOtScriptDomainStr)
  } catch (e) {
    return null
  }
}

/**
 * fetching html response from API
 */
let fetchHTML = async (url) => {
  try {
    let response = await axios.get(url)
    return response
  } catch (e) {
    return null
  }
}

let getOtScriptDomain = key => {
  const otScriptDomain = getOtScriptDomains()

  if (otScriptDomain) {
    try {
      return otScriptDomain[key]
    } catch (e) {
      return null
    }
  } else {
    return null
  }
}

let getSwitch = key => {
  const switches = getSwitches()

  if (switches) {
    try {
      return switches[key]
    } catch (e) {
      return null
    }
  } else {
    return null
  }
}

let isValidURL = url => {
  var pattern = null
  if (!url) {
    return false
  }

  pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // fragment locator
  return !!pattern.test(url)
}

const addObjectAttr = (
  r,
  attr,
  val
) => {
  for (let key in val) {
    const dataKey = `data-${key}`,
      dataVal = val[key]

    if (dataVal && typeof dataVal === "boolean") {
      r.setAttribute(dataKey, dataVal)
    } else if (typeof dataVal === "string") {
      r.setAttribute(dataKey, dataVal)
    } else if (typeof dataVal === "function") {
      r.setAttribute(dataKey, dataVal)
    }
  }
}

let loadStyle = style => {
  if (
    !style
  ) {
    return
  }

  const insertAfter = style.insertAfter && document.getElementById(style.insertAfter)
  const r = document.createElement("link")
  style['rel'] = "stylesheet"
  style['type'] = "text/css"

  for (let attr in style) {
    const val = style[attr]
    typeof val === "object" ? addObjectAttr(r, attr, val) : r.setAttribute(attr, val)
  }

  insertAfter ? insertAfter.parentNode.insertBefore(r, insertAfter.nextSibling) : document.getElementsByTagName("head")[0].appendChild(r)
}

let loadStyles = styles => {
  for (let index in styles) {
    const style = styles[index]
    loadStyle(style)
  }
}

let loadScript = script => {
  if (
    !script
  ) {
    return
  }

  const r = document.createElement("script")
  r.type = "text/javascript"

  for (let attr in script) {
    const val = script[attr]

    if (
      attr === 'jsData'
    ) {
      let oScriptText = document.createTextNode(val)
      r.appendChild(oScriptText)
      // } else if (
      //   attr === 'onload'
      // ) {
      //   r.addEventListener('load', val)
    } else {
      typeof val === "object" ? addObjectAttr(r, attr, val) : r.setAttribute(attr, val)
    }
  }

  document.getElementsByTagName(script.inHead ? "head" : "body")[0].appendChild(r)

  if (script.onload && typeof script.onload === "function") {
    r.onload = script.onload
  }
}

let loadScripts = scripts => {
  for (let index in scripts) {
    const script = scripts[index]
    loadScript(script)
  }
}

const getCookie = cname => {
  if (
    isBrowser()
  ) {
    const name = `${cname}=`
    const ca = document.cookie.split(";")

    for (
      let cookieVal of ca
    ) {
      cookieVal = cookieVal.trimLeft()
      if (
        cookieVal.indexOf(name) === 0
      ) {
        const cookieValToReturn = cookieVal.substring(name.length, cookieVal.length)
        if (
          cname === 'wu_device_id' &&
          cookieValToReturn &&
          cookieValToReturn.startsWith('function')
        ) {
          setCookie('wu_device_id', '')
          return ""
        }
        return cookieValToReturn
      }
    }
  }
  return ""
}

const setCookie = (cname, cvalue, exdays) => {
  let d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  let expires = `expires=${d.toUTCString()}`
  let cookie = `${cname}=${cvalue};${expires};path=/;`
  if (window.location.protocol.indexOf("https") !== -1) {
    cookie += "secure;"
  }
  document.cookie = cookie
}

const isCategoryEnabled = (category, isGDPR, userCookieValue) => {
  if (!userCookieValue && isGDPR) {
    userCookieValue = ",C0001,"
  }
  if (!category || !userCookieValue) return true
  return userCookieValue.indexOf(category) > -1
}

const isBrowser = () => typeof window !== "undefined"

const isHomePage = () => {
  if (isBrowser()) {
    return ['home', 'currency-converter'].some(page => window.location.href.indexOf(page))
  }
  return false
}

const getEnv = () => {
  let env = null
  if (isBrowser()) {
    const {
      protocol,
      hostname,
      port
    } = window.location
    if (hostname.includes("localhost")) {
      env = "https://wudispatcher-qa11.qawesternunion.com"
    } else {
      if (!window.location.origin) {
        // Internet Explorer does not have access to window.location.origin
        window.location.origin = `${protocol}//${hostname.indexOf('origin') > -1 ? 'www2.westernunion.com' : hostname}${port ? `:${port}` : ""
        }`
      }
      env = window.location.origin
    }
    return env
  }
  return false
}

const getEnvironment = () => {
  return isBrowser() ? window.pageData.env : process.env.NODE_ENV
}

/**
 * Find whether the page is mywu || blog || vulcan as default
 * @returns string confirming the page type
 */
const getPageType = () => {
  if (window.pageData.env.includes("blog")) return "blog"
  else if (window.pageData.env.includes("mywu")) return "mywu"
  return "default"
}

let isGDPRCountry = (countryList) => {
  let currentCountry = getCurrentCountry().toUpperCase()
  return countryList.indexOf(currentCountry) > -1
}

let getCurrentCountry = () => {
  return isBrowser() ? window.pageData.country : process.env.country
}

let getCurrentLanguage = () => {
  return isBrowser() ? window.pageData.language : process.env.language
}

let isCookieBucketingEnabled = (countryList) => {
  let currentCountry = getCurrentCountry().toUpperCase()
  return (
    countryList &&
    countryList === currentCountry
  )
}

let getAssetVersion = () => {
  if (isBrowser()) {
    const activeVersion = window.sessionStorage.getItem("activeVersion")
    if (activeVersion) {
      return JSON.parse(activeVersion).assetVersion
    }
  }
  return
}

/**
 * adding the base url to content path url
 */
let addBaseUrlToString = (data, baseURL) => {
  return data && typeof data === "string" && data.replace(/\/content\/wucom\//gi, `${baseURL}/content/wucom/`)
}

/**
 * adding common script properties
 */
let addScriptProps = (url) => {
  let script = document.createElement(`script`)
  script.async = true
  script.src = url
  return script
}

/**
 * replace base url in the string with domain
 */
let replaceBaseUrl = (url) => {
  return url && url.replace(/{{baseURL}}/, getEnv())
}

const getCountryCodeAndLanguage = () => {
  return {
    countryCode: getCookie("WUCountryCookie_").toLowerCase() || 'us',
    langCode: getCookie("WULanguageCookie_").toLowerCase() || 'en'
  }
}

/**
 * delete cookie
 * @param {string} name cookie name
 */
const deleteCookie = name => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

const getParameterByName = (
  name,
  url = window.location.href
) => {
  name = name.replace(/[\\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const setUtmData = () => {
  const utm_data = { 'AFFILIATES': 'afc_1,aff,aff_,aff-aw,aff-fri,afyf,americreditfinance,aupost,cosk.net,ffiliation,guardian,net,netaf,netaffiliation,tradetracker,us_aff,us_cj0311,us_cpa,wucanadaps,http://startsear.ch/?aff', 'DISPLAY': 'dfa,displ,display,display_,dlay,nexcom,nigeriaso8_,splay', 'EMAIL': 'abandon0transactions,abandon1transactions12mon,emai,email,email_,eml,fbopsweeps2012,serviceeu110908,usereg0409', 'INTERNAL': 'au_en_locator,es_ch_hp_promoad1,hp_hero,hp_promad,other_agentlocator_d2bq312,other_agentlocator_onlineq312,other_agentlocator_q312,other_priceit_d2bq312,other_priceit_onlineq312,other_transferstatus_d2bq312,other_transferstatus_onlineq312,otherdebid0911,us_en_hp_hero_banner,us_en_hp_promoad,wucanada,wucanadaal,wucanadacorp,wucanadaap,wucanadaaps,wucanadasend,wucanadaaxs,wugabonps', 'MOBILE': 'mobile,mobile_', 'OTHER': '917286_62519727_239743728,917286_62519727_239743728,917286_67297887_240960096,917286_74246682_248943900,917286_74294882_248723639,americreditfinance6 -,dootv_2dootv,mx_bts_0811,pounds sterling to sek_paneuro_se_q112,ramadanenglishus western union promotion code', 'RETAIL': 'ret,ret_,retail', 'SEARCHENGINES': 'google,yandex,yahoo,msn,bing,creativecommons,duckduckgo,swisscows,startpage,gibiru,wiki,onesearch,ask,baidu,naver,aol,excite,lycos,chacha, wolframalpha,givewater,ekoru,ecosia', 'SOCIALSITES': 'facebook,instagram,twitter,reddit,Pinterest' }
  sessionStorage.setItem('UTMSourcesList', JSON.stringify(utm_data))
}

let getQueryParameter = () => {
  const urlParams = window.location.search || window.location.hash

  return urlParams
}

let getCanonicalUrl = (
  canonical
) => {
  if (
    !isBrowser() ||
    !canonical
  ) {
    return ''
  }

  const urlParams = getQueryParameter()

  return `${canonical}${urlParams}`
}

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const guid = () => {
  return (`${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`)
}

const getUniqueId = () => {
  const guId = guid()
  document.cookie = `wu_device_id=${guId}; path=/`
  return guId
}

/**
 * get Country for blogs pages
 * @returns if category set then return country category else look for AkCountry Cookie else set to US
 */
const getCountryBlogType = () => {
  let dataCategoryType = document.querySelector('.category-name')?.getAttribute('data-category-slug')
  if(dataCategoryType?.length !== 2) {
    dataCategoryType = getCookie("AKCountry") || 'US'
  }
  return dataCategoryType?.toUpperCase()
}

const mParticleArgShift = (e1, o1) => {
  return () => {
    if (o1) {
      e1 = `${o1}.${e1}`
    }
    let args = []
    e1 && args.push(e1)
    o1 && args.push(o1)
    const t5 = Array.prototype.slice.call(args)
    t5.unshift(e1)
    window.mParticle.config.rq.push(t5)
  }
}

/**
 * stateMParticle values can be 'r3mparticle, r4mparticle, disabled'
 * @returns String 'r3mparticle, r4mparticle, disabled'
*/
const findMparticleState = () => {
  if (getPageType() === 'blog') {
    return (JSON.parse(window.pageData.R4_DEPLOYED_COUNTRIES).indexOf(getCountryBlogType()) === -1) ? "r3mparticle" : "r4mparticle"
  }
  const isMrBeast = window.location.href.indexOf('/mrbeast') > -1
  let stateMParticle = window.pageData.dataCountryThemeSetting &&
    window.pageData.dataCountryThemeSetting.nodes[0].countryThemeSettings.mParticle.stateMParticle
  const enableNextGenWeb = getCookie('enableNextGenWeb')
  if(isMrBeast) {
    const akCountry = getCookie('AKCountry') || getCookie('WUCountryCookie_') || 'US'
    if(akCountry.toUpperCase() === 'US') {
      return "r3mparticle"
    }else if(window.pageData.R4_DEPLOYED_COUNTRIES && JSON.parse(window.pageData.R4_DEPLOYED_COUNTRIES).indexOf(akCountry.toUpperCase()) > -1) {
      return "r4mparticle"
    }
    return "r3mparticle"
  }
  if (stateMParticle === 'r3mparticle' && enableNextGenWeb === 'true') return 'r4mparticle'
  sessionStorage.setItem('stateMParticle', stateMParticle)
  return stateMParticle
}

/**
 * get staticassets type  based on pageData env
 * @return string 'blogs-staticassets', 'staticassets'
*/
const getStaticAssetsType = () => {
  if (window.pageData.env.indexOf("blog") > -1) {
    return `blogs-staticassets`
  } else if (window.pageData.env.indexOf("mywu") > -1) {
    return `mywu-ssr-staticassets`
  }
  return `staticassets`
}

const getWuPrepaidLink = () => {
  const getCurrentEnv = getEnvironment()
  if(getCurrentEnv === "development") {
    return `https://wu-tst4.outsystemsenterprise.com/Prepaid/`
  }
  return null
}

/**
 * method for returning true or false based on template being v2
 * @param {*} templateName string
 * @returns boolean
 */
const isV2Template = (
  templateName
) => templateName === "WUDS 2.0 Template"  || templateName === "Corridor Template"

const isMobileWeb = () => {
  return !!(navigator?.userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

export {
  windowClick,
  setSwitches,
  getPageUrl,
  getSwitches,
  getSwitch,
  isValidURL,
  loadScript,
  loadScripts,
  loadStyle,
  loadStyles,
  getCookie,
  setCookie,
  isCategoryEnabled,
  isBrowser,
  isHomePage,
  getEnv,
  getCurrentCountry,
  getCurrentLanguage,
  isCookieBucketingEnabled,
  getAssetVersion,
  getOtScriptDomain,
  isGDPRCountry,
  fetchHTML,
  addBaseUrlToString,
  addScriptProps,
  replaceBaseUrl,
  getCountryCodeAndLanguage,
  deleteCookie,
  getEnvironment,
  getParameterByName,
  setUtmData,
  getCanonicalUrl,
  getUniqueId,
  mParticleArgShift,
  findMparticleState,
  getUrl,
  getStaticAssetsType,
  getWuPrepaidLink,
  isV2Template,
  isMobileWeb,
  getPageType,
  getCountryBlogType
}
