import hideAppBlock from "./hide-app-block"

const oneLinkSmartScript = options => {
  try {
    if (
      options
    ) {
      if (
        options.url
      ) {
        return {
          src: options.url,
          beforeLoad: !!options.beforeLoad,
          defer: !!options.defer,
          async: !!options.async,
          onDCL: !!options.onDCL,
          'onload': () => {
            const defaultValueOneLink = window.location.search ? encodeURIComponent(window.location.search.substring(1)) : 'dynamic_corridor_page'
            const mediaSource = {keys: [""], defaultValue: defaultValueOneLink}
            hideAppBlock()
            const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
              oneLinkURL: `${window.pageData.oneLinkURL}${window.pageData.dataCountryThemeSetting.nodes[0].countryThemeSettings.oneLinkTemplateIdProd}/`,
              afParameters: {
                mediaSource
              },
            })

            if (result) {
              const result_url = result.clickURL
              const qrCodeElements = document.querySelectorAll(".onelink_qrcode")
              const appCodeElements = document.querySelectorAll(".onelink_appstore")
              Array.from(qrCodeElements).forEach((qrCodeElement, index) => {
                qrCodeElement.parentElement?.querySelector('img')?.classList.add('d-none')
                qrCodeElement.id = `onelink-qrcode-${index}`
                window.AF_SMART_SCRIPT.displayQrCode(qrCodeElement.id)
                let canvas = qrCodeElement.querySelector('canvas')
                canvas?.classList.add('w-100')
                canvas?.setAttribute('style', "border: 5px solid white;")
              })

              Array.from(appCodeElements).forEach((el) => {
                el.href =  result_url
              })
            }
          }
        }
      }

      return
    }

    console.log(`Please verify the oneLinkSmartScript url: ${options.url}`)
  } catch (e) {
    console.log(`There is an error. oneLinkSmartScript cannot be loaded.`)
  }
}

export default oneLinkSmartScript